import { gql } from '@apollo/client';
import { GetServerSideProps } from 'next';
import { getApolloClientServerSide } from '../../../clients/hasura';
import VehicleListingPage from '../../../components/VehicleListingPage';
import { DropInAdType } from '../../../components/VehicleListingPage/components/DropInAd';
import { getShowroomServerSide } from '../../../services/showrooms/getShowroomServerSide';
import { VehicleListGetListQuery, VehicleListGetListQueryVariables } from '../../../types/graphql';

type Props = {
  vehicleList: VehicleListGetListQuery['vehicle_lists'][number];
};

export default function VehicleList(props: Props) {
  const { vehicleList } = props;

  return (
    <VehicleListingPage
      title={vehicleList.title}
      description={vehicleList.description}
      headerImageUrl={vehicleList.header_image_url}
      headerTextColor={vehicleList.header_text_color}
      headerSize={vehicleList.header_size as 'small' | 'medium' | 'large'}
      metaTitle={vehicleList.meta_title}
      metaDescription={vehicleList.meta_description}
      whereClause={vehicleList.where_clause}
      filterFields={vehicleList.filter_fields}
      dropInAds={vehicleList.drop_in_ads as Array<DropInAdType>}
      defaultSettlementMethod={vehicleList.default_settlement_method}
      financeSettings={vehicleList.showroom.public_settings}
    />
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const apolloClient = getApolloClientServerSide(context.req, context.res);
  const showroom = await getShowroomServerSide(context.req, context.res);

  const vehicleListCode = (context.params.vehicleListCode as string[]).join('/');

  const response = await apolloClient.query<VehicleListGetListQuery, VehicleListGetListQueryVariables>({
    query: gql`
      query VehicleListGetList($showroomId: uuid, $code: String) {
        vehicle_lists(where: { showroom_id: { _eq: $showroomId }, code: { _eq: $code } }) {
          title
          description
          header_image_url
          meta_title
          meta_description
          where_clause
          drop_in_ads
          header_text_color
          header_size
          default_settlement_method
          filter_fields(order_by: { order: asc }) {
            label
            name
            options
            location
          }
          showroom {
            public_settings
          }
        }
      }
    `,
    variables: {
      showroomId: showroom.id,
      code: vehicleListCode,
    },
  });
  const vehicleList = response.data?.vehicle_lists[0];

  if (!vehicleList) {
    return {
      notFound: true,
      props: {},
    };
  }

  return {
    props: {
      vehicleList,
    },
  };
};
